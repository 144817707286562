import { useDispatch } from "react-redux";
import {
  addToast,
  clearToast,
  dismissToast,
  removeToast,
} from "../features/toastr/toastrSlice";

const buildToastr = (title, message, type, messageTemplate) => {
  return {
    id: +new Date(),
    title: title,
    message: message,
    type: type,
    dismissed: false,
    messageTemplate
  };
};

const dispatchAddToast = (dispatch, toast) => {
  dispatch(addToast(toast));

  setTimeout(() => {
    dispatch(dismissToast(toast));
  }, 5700);

  setTimeout(() => {
    dispatch(removeToast(toast));
  }, 6600);
};

const useToastr = () => {
  const dispatch = useDispatch();
  const showSuccess = (title, message) => {
    const toastr = buildToastr(title, message, "success");
    dispatchAddToast(dispatch, toastr);
  };

  const showInfo = (title, message) => {
    const toastr = buildToastr(title, message, "info");
    dispatchAddToast(dispatch, toastr);
  };

  const showWarning = (title, message) => {
    const toastr = buildToastr(title, message, "warning");
    dispatchAddToast(dispatch, toastr);
  };

  const showError = (title, message, messageTemplate) => {
    const toastr = buildToastr(title, message, "danger", messageTemplate);
    dispatchAddToast(dispatch, toastr);
  };

  const showBlack = (title, message) => {
    const toastr = buildToastr(title, message, "black");
    dispatchAddToast(dispatch, toastr);
  };

  const showWhite = (title, message) => {
    const toastr = buildToastr(title, message, "white");
    dispatchAddToast(dispatch, toastr);
  };

  const clear = () => {
    dispatch(clearToast());
  };

  return {
    showSuccess,
    showInfo,
    showWarning,
    showError,
    showBlack,
    showWhite,
    clear,
  };
};

export default useToastr;
