import axios from "axios";
import { authProvider } from "../providers/authProvider";
import CustomerService from "./CustomerService";

const isCustomerView = process.env.REACT_APP_IS_CUSTOMER_VIEW === "true";

// const API_ROOT = `API LINK`;
// const API_ROOT = `${process.env.REACT_APP_API_ROOT}`;
const handleErrors = (e, isThrow) => {
  if (isCustomerView && e.response.status === 401) {
    CustomerService.onUnauthorized();
  }

  if (isThrow) throw e;
};

const handleResponse = (res) => {
  return res && res.data;
};

const beforeRequest = async (config) => {
  return config;
};

const getToken = () => {
  if (isCustomerView) return sessionStorage.getItem("gpi_token");

  return authProvider.getAccountInfo()?.jwtIdToken;
};

const createApi = async (root, config) => {
  let options = {
    baseURL: root,
    //baseURL: API_ROOT,
    timeout: 180000,
  };

  const token = getToken();

  if (token && token.trim().length > 0)
    options.headers = { Authorization: `Bearer ${token}` };

  options.headers = {
    ...options.headers,
    ...getCustomHeaders(),
  };

  const api = axios.create(options, config);
  api.interceptors.request.use(beforeRequest);

  return api;
};

const getCustomHeaders = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const activeModule = urlParams.get("activeModule");
  const activeSubmodule = urlParams.get("activeSubmodule");

  return {
    Module: activeModule,
    Submodule: activeSubmodule,
  };
};

const RequestFactory = {
  get: async (root, url, config = {}) =>
    (await createApi(root, config))
      // .get(`${API_ROOT}${url}`)
      .get(`${root}${url}`, config)
      .then(handleResponse)
      .catch(handleErrors),
  post: async (root, url, data, onAxiosConfig = () => undefined) => {
    const axiosInstance = await createApi(root);
    let axiosConfig = onAxiosConfig();

    return axiosInstance
      .post(`${root}${url}`, data, axiosConfig)
      .then(handleResponse)
      .catch((e) => handleErrors(e, true));
  },
  put: async (root, url, data) =>
    await createApi()
      // .put(`${API_ROOT}${url}`, data)
      .put(`${root}${url}`, data)
      .then(handleResponse)
      .catch(handleErrors),
  patch: async (root, url, data) =>
    (await createApi()).patch(`${root}${url}`, data).then(handleResponse),
  delete: async (root, url) => {
    return (await createApi())
      .delete(`${root}${url}`)
      .then(handleResponse)
      .catch(handleErrors);
  },
  download: async (root, url, data) => {
    let options = {
      // baseURL: API_ROOT,
      baseURL: root,
      timeout: 180000,
    };

    const token = getToken();
    if (token && token.trim().length > 0)
      options.headers = { Authorization: `Bearer ${token}` };

    const api = axios.create(options);
    api.interceptors.request.use(beforeRequest);
    return api
      .post(url, data, { responseType: "blob" })
      .then(handleResponse)
      .catch(handleErrors);
  },
  downloadRaw: async (root, url, data) => {
    let options = {
      baseURL: root,
      timeout: 180000,
    };

    const token = getToken();
    if (token && token.trim().length > 0)
      options.headers = { Authorization: `Bearer ${token}` };

    const api = axios.create(options);
    api.interceptors.request.use(beforeRequest);
    return api.post(url, data, { responseType: "blob" });
  },
  downloadGet: async (root, url) => {
    let options = {
      baseURL: root,
      timeout: 180000,
    };

    const token = getToken();
    if (token && token.trim().length > 0)
      options.headers = { Authorization: `Bearer ${token}` };

    const api = axios.create(options);
    api.interceptors.request.use(beforeRequest);
    return api
      .get(url, { responseType: "blob" })
      .then(handleResponse)
      .catch(handleErrors);
  },
  upload: (root, url, formData, uploadProgressEvent) => {
    let options = {
      // baseURL: API_ROOT,
      baseURL: root,
      timeout: 180000,
    };

    const token = getToken();
    if (token !== null && token.trim().length > 0)
      options.headers = { Authorization: `Bearer ${token}` };

    options.headers = {
      ...options.headers,
      ...getCustomHeaders(),
    };

    const api = axios.create(options);
    api.interceptors.request.use(beforeRequest);
    return api
      .post(url, formData, {
        headers: { "content-type": "multipart/form-data" },
        onUploadProgress: uploadProgressEvent,
      })
      .then(handleResponse)
      .catch(handleErrors);
  },
};

export default RequestFactory;
