import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { addBusinessDays } from "date-fns";
import {
  ErrorMessage,
  Field,
  FieldProps,
  Form,
  FormikProvider,
  useFormik,
} from "formik";
import { max, sortBy, uniqBy } from "lodash";
import {
  AutoComplete,
  AutoCompleteChangeParams,
  AutoCompleteCompleteMethodParams,
  AutoCompleteSelectParams,
} from "primereact/autocomplete";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { MultiSelect } from "primereact/multiselect";
import { classNames } from "primereact/utils";
import { useEffect, useState } from "react";
import * as yup from "yup";
import { authProvider } from "../../../../../providers/authProvider";
import FlexIntegrationService from "../../../../../services/FlexIntegrationService";
import GMAWBUsersService from "../../../../../services/GMAWBUsersService";
import ProjectService from "../../../../../services/ProjectService";
import {
  enumerateDaysBetweenDates,
  getUniqueDates,
} from "../../../../../shared/date-helper/DateHelper";
import { E2EProjectLineModel } from "../../../../../shared/models/E2EProject.model";
import { E2EUserPaginatedModel } from "../../../../../shared/models/E2EUserPaginatedModel";
import { PredefinedTask } from "../../../../../shared/models/PredefinedTask.model";
import { CustomerVisibility } from "../../enums/customer-visibility";
import HandlerType from "../../enums/handler-type";
import ProjectTaskStatus from "../../enums/project-task-status";
import ProjectCollaboratorAssignmentDropdown from "../project-collaborator-assignment-dialog/project-collaborator-assignment-dropdown/ProjectCollaboratorAssignmentDropdown";
import { ProjectUserSource } from "../project-collaborator-assignment-dialog/ProjectCollaboratorAssignmentDialog";
import classes from "./AdHocTaskFormDialog.module.scss";
import { GMATCTaskName } from "../../../../../utils/constants/flex-task-name.constants";
import { CreateAdHocTaskModel } from "../../../../../shared/models/CreateAdHocTaskModel";
import moment from "moment";
import useToastr from "../../../../../hooks/useToastr";
import PmrE2EOnHoldReason from "../../interfaces/pmr-e2e-project-onhold-reason";

const handlerSchema = yup.object().shape({
  normalizedEmail: yup.string().required(),
  displayName: yup.string().required(),
});

const adHocTaskSchema = yup.object().shape({
  taskName: yup.string().label("Task Name").required(),
  predefinedTaskTypeId: yup.string().nullable(),
  taskHandler: handlerSchema.label("Task Handler").required(),
  shortDescription: yup.string().label("Short Description"),
  milestoneName: yup.string().label("Milestone Name").required(),
  milestoneHandler: handlerSchema.label("Milestone Handler").required(),
  orderLineAssociations: yup
    .array()
    .label("Order Line Association")
    .of(yup.string()),
  predecessor: yup.string().label("Predecessor").nullable(),
  flexTaskStatus: yup.number().label("Flex Task Status").required(),
  onHoldReason: yup
    .string()
    .label("On Hold Reason")
    .nullable()
    .when("flexTaskStatus", (flexTaskStatus, schema) => {
      if (flexTaskStatus === ProjectTaskStatus.OnHold) return schema.required();
      return schema;
    }),
  startDate: yup
    .date()
    .label("Start Date")
    .required()
    .when("minStartDate", (minStartDate, schema) => {
      if (minStartDate) {
        return schema.min(
          yup.ref("minStartDate"),
          "Start Date can't be earlier than order booked date."
        );
      }

      return schema;
    }),
  dueDate: yup
    .date()
    .label("Due Date")
    .min(yup.ref("startDate"), "Due date can't be earlier than the start date")
    .required(),
  customerVisibility: yup.number().label("Customer Visibility").required(),
  comment: yup.string().label("Comment"),
  minStartDate: yup.date().nullable(),
});

const initialHandlerDropdownOptions: ProjectUserSource = {
  currentSkip: 0,
  currentTake: 5,
  currentSearchPhrase: "",
  data: [],
};

const taskStatusOptions = [
  {
    label: "Not Scheduled",
    value: 0,
  },
  {
    label: "In Progress",
    value: 200,
  },
  { label: "On Hold", value: 300 },
  {
    label: "Cancelled",
    value: 500,
  },
  {
    label: "Completed",
    value: 400,
  },
];

const customerVisiblityOptions = [
  { label: "Not Visible", value: CustomerVisibility.NotVisible },
  { label: "Visible", value: CustomerVisibility.Visible },
];

type Props = {
  visible: boolean;
  onHide: () => void;
  onNewTaskCreated: () => void;
  e2eProjectId: string;
  e2eProjectName: string;
};

const AdHocTaskFormDialog = (props: Props) => {
  const initialAdHocTaskValue: CreateAdHocTaskModel = {
    e2eProjectId: props.e2eProjectId,
    taskName: "",
    taskHandler: undefined as E2EUserPaginatedModel | undefined,
    shortDescription: "",
    milestoneName: "",
    milestoneHandler: undefined as E2EUserPaginatedModel | undefined,
    orderLineAssociations: [] as string[],
    predecessor: null,
    flexTaskStatus: null as ProjectTaskStatus | null,
    onHoldReason: null,
    onHoldReasonStatusDetailId: null,
    startDate: new Date(),
    dueDate: undefined as Date | undefined,
    customerVisibility: CustomerVisibility.NotVisible,
    comment: "",
    minStartDate: null,
    predefinedTaskTypeId: null as null | string,
  };

  const formik = useFormik({
    initialValues: initialAdHocTaskValue,
    onSubmit: handleSubmit,
    validationSchema: adHocTaskSchema,
  });

  const {
    setFieldValue,
    values,
    setValues,
    resetForm,
    submitForm,
    isSubmitting,
  } = formik;
  const [flexHandlerDropdownOptions, setFlexHandlerDropdownOptions] =
    useState<ProjectUserSource>(initialHandlerDropdownOptions);
  const [milestoneHandlerDropdownOptions, setMilestoneHandlerDropdownOptions] =
    useState<ProjectUserSource>(initialHandlerDropdownOptions);
  const [holdReasonOptions, setHoldReasonOptions] = useState<
    PmrE2EOnHoldReason[]
  >([]);
  const [predefinedTasks, setPredefinedTasks] = useState<PredefinedTask[]>([]);
  const [predefinedTasksSuggestions, setPredefinedTasksSuggestions] =
    useState<PredefinedTask[]>();
  const [predefinedMilestoneNames, setPredefinedMilestoneNames] = useState<
    string[]
  >([]);
  const [
    predefinedMilestoneNamesSuggestions,
    setPredefinedMilestoneNamesSuggestions,
  ] = useState<string[]>();
  const [selectedPredefinedTask, setSelectedPredefinedTask] =
    useState<PredefinedTask | null>();
  const [predecessorOptions, setPredecessorOptions] = useState<string[]>([]);
  const { showSuccess, showError } = useToastr();
  const [e2eProjectLines, setE2EProjectLines] = useState<E2EProjectLineModel[]>(
    []
  );

  const userInfo = authProvider.getAccountInfo();
  const userAsHandlerDetails: E2EUserPaginatedModel = {
    displayName: userInfo?.account.name || "",
    normalizedEmail: userInfo?.account.idToken.email || "",
  };

  useEffect(() => {
    if (props.visible) {
      resetForm();
      prepopulateWithDefaultValues();
      fetchInitialTaskHandlerOptions();
      fetchHoldReasonOptions();
      fetchPredefinedTasks();
      fetchInitialMilestoneHandlerOptions();
      fetchE2EProjectLines();
    }
  }, [props.visible]);

  useEffect(() => {
    const orderBookedDates = e2eProjectLines
      .filter((projectLine) =>
        values.orderLineAssociations.includes(projectLine.id!)
      )
      .map((projectLine) => projectLine.orderBookedDate);

    const minStartDate = max(orderBookedDates);
    setFieldValue("minStartDate", minStartDate);
  }, [values.orderLineAssociations]);

  useEffect(() => {
    if (values.flexTaskStatus !== ProjectTaskStatus.OnHold) {
      setFieldValue("onHoldReason", null);
    }
  }, [values.flexTaskStatus]);

  useEffect(() => {
    fetchPredecessorOptions();
  }, [values.orderLineAssociations]);

  useEffect(() => {
    if (props.e2eProjectId) fetchPredefinedMilestoneNames();
  }, [props.e2eProjectId]);

  const fetchE2EProjectLines = async () => {
    const result = await ProjectService.getE2EProjectLines(props.e2eProjectId);
    setE2EProjectLines(result.data);
  };

  async function handleSubmit() {
    const onHoldReasonStatusDetailId = values.onHoldReason
      ? holdReasonOptions.find(
          (reason) => reason.taskStatusReason === values.onHoldReason
        )?.taskStatusDetailId
      : null;

    const model: CreateAdHocTaskModel = {
      ...values,
      startDate: moment(values.startDate).format("YYYY-MM-DD"),
      dueDate: moment(values.dueDate).format("YYYY-MM-DD"),
      duration: getDuration(),
      onHoldReasonStatusDetailId,
    };

    try {
      await ProjectService.createAdHocTask(model);
      showSuccess(
        "Success",
        `Ad Hoc Task <b>'${values.taskName}'</b> for <b>'${props.e2eProjectName}'</b> successfully created.`
      );

      props.onNewTaskCreated();
    } catch (error) {
      showError(
        "Error",
        "Failed to create Ad Hoc Task. Please try again. Contact support team if issue persists."
      );
    }
  }

  const prepopulateWithDefaultValues = () => {
    setValues(initialAdHocTaskValue);
    setFieldValue("taskHandler", userAsHandlerDetails);
    setFieldValue("milestoneHandler", userAsHandlerDetails);
    setFieldValue("startDate", new Date());
    setFieldValue("flexTaskStatus", ProjectTaskStatus.InProgress);
    setFieldValue("customerVisibility", CustomerVisibility.NotVisible);
  };

  const fetchPredefinedTasks = () => {
    FlexIntegrationService.getAllPredefinedTasks().then((result) => {
      setPredefinedTasks(result.data);
    });
  };

  const fetchPredefinedMilestoneNames = async () => {
    const result = await ProjectService.getProjectDistinctMilestoneNames(
      props.e2eProjectId
    );

    setPredefinedMilestoneNames(result.data);
  };

  const fetchInitialTaskHandlerOptions = () => {
    GMAWBUsersService.getPaginatedUsers(
      0,
      5,
      HandlerType.TaskHandler,
      false,
      ""
    ).then((response) => {
      let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);
      setFlexHandlerDropdownOptions((current) => ({
        ...current,
        data: flexData,
      }));
    });
  };

  const fetchInitialMilestoneHandlerOptions = () => {
    GMAWBUsersService.getPaginatedUsers(
      0,
      5,
      HandlerType.MilestoneHandler,
      false,
      ""
    ).then((response) => {
      let flexData: E2EUserPaginatedModel[] = structuredClone(response?.data);
      setMilestoneHandlerDropdownOptions((current) => ({
        ...current,
        data: flexData,
      }));
    });
  };

  const fetchHandler = async (email: string, handlerType: HandlerType) => {
    const result = await GMAWBUsersService.getPaginatedUsers(
      0,
      1,
      handlerType,
      false,
      email
    );

    return result.data[0];
  };

  const fetchHoldReasonOptions = async () => {
    const holdReasons = await ProjectService.getTaskOnHoldStatus();
    setHoldReasonOptions(holdReasons);
  };

  const fetchPredecessorOptions = async () => {
    setPredecessorOptions([]);
    if (!values.orderLineAssociations?.length) return;

    const result = await ProjectService.getE2EProjectLinesCommonTasks(
      values.orderLineAssociations
    );
    setPredecessorOptions(result.data.filter((task) => task !== GMATCTaskName));
  };

  const dialogHeader = "Add Ad Hoc Task";
  const dialogFooter = (
    <div className={classes["dialog-footer"]}>
      <Button
        disabled={isSubmitting}
        onClick={() => props.onHide()}
        className={classes["btn-cancel"]}
      >
        CANCEL
      </Button>
      <Button
        loading={isSubmitting}
        className={classes["btn-save"]}
        onClick={submitForm}
      >
        SAVE
      </Button>
    </div>
  );

  const isOnHoldReasonEnabled =
    values.flexTaskStatus === ProjectTaskStatus.OnHold;

  const getDuration = () => {
    if (!values.startDate || !values.dueDate) return 0;
    if (values.dueDate < values.startDate) return 0;

    const dates = getUniqueDates([
      values.startDate as Date,
      values.dueDate as Date,
      ...enumerateDaysBetweenDates(
        values.startDate as Date,
        values.dueDate as Date
      ),
    ]);

    return dates.filter((date) => date.getDay() !== 6 && date.getDay() !== 0)
      .length;
  };

  const searchPredefinedTasks = ({
    query: search,
  }: AutoCompleteCompleteMethodParams) => {
    if (!search) {
      setPredefinedTasksSuggestions([]);
      return;
    }

    const filtered =
      predefinedTasks?.filter((task) =>
        task.name.toUpperCase().startsWith(search.toUpperCase())
      ) || [];

    const sorted = sortBy(filtered, "name");

    setPredefinedTasksSuggestions(sorted);
  };

  const handlePredefinedTaskSelected = async (e: AutoCompleteSelectParams) => {
    const selectedPredefinedTask: PredefinedTask = e.value;

    setSelectedPredefinedTask(selectedPredefinedTask);
    setFieldValue("taskName", selectedPredefinedTask.name);
    setFieldValue("shortDescription", selectedPredefinedTask.description || "");
    setFieldValue("predefinedTaskTypeId", selectedPredefinedTask.taskTypeId);

    if (selectedPredefinedTask.estimatedDurationDays !== null) {
      const newStartDate = new Date();
      const newDueDate = addBusinessDays(
        newStartDate,
        (selectedPredefinedTask.estimatedDurationDays || 0) - 1
      );

      setFieldValue("startDate", newStartDate);
      setFieldValue("dueDate", newDueDate);
    }

    if (selectedPredefinedTask.taskOwner) {
      const taskHandler = await fetchHandler(
        selectedPredefinedTask.taskOwner,
        HandlerType.TaskHandler
      );

      if (taskHandler) {
        setFieldValue("taskHandler", taskHandler);
        setFieldValue("milestoneHandler", taskHandler);
      }
    }
  };

  const handleTaskNameChanged = (e: AutoCompleteChangeParams) => {
    setFieldValue("taskName", e.value);
    setSelectedPredefinedTask(null);
  };

  const predefinedTaskSuggestionItemTemplate = (item: PredefinedTask) => {
    return (
      <div className={classes["predefined-task-suggestion-item"]}>
        <div>{item.name}</div>
        {item.description && (
          <div className={classes["predefined-task-description"]}>
            {item.description}
          </div>
        )}
      </div>
    );
  };

  const searchPredefinedMilestoneNames = ({
    query: search,
  }: AutoCompleteCompleteMethodParams) => {
    if (!search) {
      setPredefinedTasksSuggestions([]);
      return;
    }

    const filtered =
      predefinedMilestoneNames?.filter((name) =>
        name.toUpperCase().includes(search.toUpperCase())
      ) || [];

    const sorted = filtered.sort();
    setPredefinedMilestoneNamesSuggestions(sorted);
  };

  const orderLineAssociationOptions = (e2eProjectLines || []).map(
    (projectLine) => ({
      label: `${projectLine.flexProjectNumber} | ${projectLine.orderLineDescription}`,
      value: projectLine.id,
    })
  );

  const constructFlexHandlerDropdownOptions = () => {
    let result = flexHandlerDropdownOptions.data;

    if (!!values.taskHandler?.normalizedEmail) {
      result = [values.taskHandler, ...flexHandlerDropdownOptions?.data];
    }

    result = uniqBy(result, "normalizedEmail");

    return {
      ...flexHandlerDropdownOptions,
      data: result,
    } as ProjectUserSource;
  };

  const constructMilestoneHandlerDropdownOptions = () => {
    let result = milestoneHandlerDropdownOptions.data;

    if (!!values.taskHandler?.normalizedEmail) {
      result = [values.taskHandler, ...milestoneHandlerDropdownOptions?.data];
    }

    result = uniqBy(result, "normalizedEmail");

    return {
      ...milestoneHandlerDropdownOptions,
      data: result,
    } as ProjectUserSource;
  };

  const constructLabel = (fieldName: string) => {
    const details = adHocTaskSchema.describe().fields[fieldName] as any;

    const isRequired = details.tests.some(
      ({ name }: any) => name === "required" || name === "min"
    );

    return (
      <label className={classNames(isRequired && classes["required"])}>
        {details.label}
      </label>
    );
  };

  return (
    <Dialog
      className={classes["dialog"]}
      header={dialogHeader}
      footer={dialogFooter}
      visible={props.visible}
      onHide={props.onHide}
    >
      <FormikProvider value={formik}>
        <Form className={classes["form"]}>
          <div className={classes["row"]}>
            <Field name="taskName">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["task-name-field"],
                    classes["field"],
                    classes["customer-visible-field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <AutoComplete
                    {...field}
                    onChange={handleTaskNameChanged}
                    className={classNames(
                      "form-control",
                      meta.touched && meta.error && "p-invalid"
                    )}
                    itemTemplate={predefinedTaskSuggestionItemTemplate}
                    completeMethod={searchPredefinedTasks}
                    onSelect={handlePredefinedTaskSelected}
                    suggestions={predefinedTasksSuggestions}
                    maxLength={250}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="taskHandler">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["task-handler-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <ProjectCollaboratorAssignmentDropdown
                    className={classNames(
                      meta.touched && meta.error && "p-invalid"
                    )}
                    onBlur={field.onBlur}
                    value={field.value}
                    setValue={(value) => {
                      setFieldValue(field.name, value);
                    }}
                    isDisabled={false}
                    dropdownOptions={constructFlexHandlerDropdownOptions()}
                    setDropdownOptions={setFlexHandlerDropdownOptions}
                    handlerType={HandlerType.FlexHandler}
                    isGmaTcTask={false}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>
          </div>

          <Field name="shortDescription">
            {({ field, meta }: FieldProps) => (
              <div className={classNames(classes["field"])}>
                {constructLabel(field.name)}

                <InputText
                  {...field}
                  maxLength={250}
                  disabled={!!values.predefinedTaskTypeId}
                  className={classNames(
                    classes["form-control"],
                    meta.touched && meta.error && "p-invalid"
                  )}
                />

                <ErrorMessage
                  component="div"
                  className={classes["invalid-error-message"]}
                  name={field.name}
                />
              </div>
            )}
          </Field>

          <div className={classes["row"]}>
            <Field name="milestoneName">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["milestone-name-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <AutoComplete
                    {...field}
                    className={classNames(
                      classes["form-control"],
                      meta.touched && meta.error && "p-invalid"
                    )}
                    completeMethod={searchPredefinedMilestoneNames}
                    suggestions={predefinedMilestoneNamesSuggestions}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="milestoneHandler">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["milestone-handler-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <ProjectCollaboratorAssignmentDropdown
                    className={classNames(
                      meta.touched && meta.error && "p-invalid"
                    )}
                    onBlur={field.onBlur}
                    value={field.value}
                    setValue={(value) => {
                      setFieldValue(field.name, value);
                    }}
                    isDisabled={false}
                    dropdownOptions={constructMilestoneHandlerDropdownOptions()}
                    setDropdownOptions={setMilestoneHandlerDropdownOptions}
                    handlerType={HandlerType.MilestoneHandler}
                    isGmaTcTask={false}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>
          </div>

          <div className={classes["row"]}>
            <Field name="orderLineAssociations">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["order-line-association-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}
                  <FontAwesomeIcon
                    title="If an Order Line Status is marked as 'Completed', the ad hoc task will be directly associated with its FLEX Project if the FLEX Project Status is not yet completed."
                    style={{ marginLeft: ".25rem", cursor: "pointer" }}
                    icon={faInfoCircle}
                  />

                  <MultiSelect
                    {...field}
                    placeholder="-- SELECT --"
                    className={classNames(
                      classes["form-control"],
                      meta.touched && meta.error && "p-invalid"
                    )}
                    filter
                    selectedItemsLabel="All"
                    options={orderLineAssociationOptions}
                    optionLabel="label"
                    optionValue="value"
                    maxSelectedLabels={orderLineAssociationOptions.length - 1}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="predecessor">
              {({ field }: FieldProps) => (
                <div
                  className={classNames(
                    classes["predecessor-field"],
                    classes["field"]
                  )}
                >
                  {constructLabel(field.name)}
                  <Dropdown
                    {...field}
                    showClear
                    disabled={!values.orderLineAssociations.length}
                    options={predecessorOptions}
                  />
                </div>
              )}
            </Field>
          </div>

          <div className={classes["row"]}>
            <Field name="flexTaskStatus">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["flex-task-status-field"],
                    classes["field"],
                    classes["customer-visible-field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <Dropdown
                    inputId={field.name}
                    {...field}
                    className={classNames(
                      meta.touched && meta.error && "p-invalid"
                    )}
                    options={taskStatusOptions}
                    optionLabel="label"
                    optionValue="value"
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="onHoldReason">
              {({ field, meta }: FieldProps) => {
                return (
                  <div
                    className={classNames(
                      classes["on-hold-reason-field"],
                      classes["field"],
                      classes["customer-visible-field"]
                    )}
                  >
                    {constructLabel(field.name)}

                    <Dropdown
                      inputId={field.name}
                      {...field}
                      name={"onHoldReason"}
                      className={classNames(
                        meta.touched && meta.error && "p-invalid"
                      )}
                      disabled={!isOnHoldReasonEnabled}
                      placeholder="--SELECT--"
                      options={holdReasonOptions}
                      optionValue="taskStatusReason"
                      optionLabel="taskStatusReason"
                    />

                    <ErrorMessage
                      component="div"
                      className={classes["invalid-error-message"]}
                      name={field.name}
                    />
                  </div>
                );
              }}
            </Field>
          </div>

          <div className={classes["row"]}>
            <Field name="startDate">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["start-date-field"],
                    classes["field"],
                    classes["customer-visible-field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <Calendar
                    {...field}
                    monthNavigator
                    yearNavigator
                    yearRange={"2018:2030"}
                    className={classNames(
                      meta.error && meta.touched && "p-invalid"
                    )}
                    minDate={new Date(2018, 1, 1)}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <Field name="dueDate">
              {({ field, meta }: FieldProps) => (
                <div
                  className={classNames(
                    classes["due-date-field"],
                    classes["field"],
                    classes["customer-visible-field"]
                  )}
                >
                  {constructLabel(field.name)}

                  <Calendar
                    {...field}
                    monthNavigator
                    yearNavigator
                    yearRange={"2018:2030"}
                    minDate={values.startDate as Date}
                    className={classNames(
                      meta.error && meta.touched && "p-invalid"
                    )}
                  />

                  <ErrorMessage
                    component="div"
                    className={classes["invalid-error-message"]}
                    name={field.name}
                  />
                </div>
              )}
            </Field>

            <div
              className={classNames(
                classes["duration-field"],
                classes["field"]
              )}
            >
              <label className={classes["required"]}>Duration</label>
              <InputText value={getDuration()} disabled={true} />
            </div>
          </div>

          <Field name="customerVisibility">
            {({ field, meta }: FieldProps) => (
              <div
                className={classNames(
                  classes["customer-visibility-field"],
                  classes["field"],
                  classes["customer-visible-field"]
                )}
              >
                {constructLabel(field.name)}

                <Dropdown
                  inputId={field.name}
                  {...field}
                  className={classNames(
                    meta.touched && meta.error && "p-invalid"
                  )}
                  options={customerVisiblityOptions}
                  optionLabel="label"
                  optionValue="value"
                />

                <ErrorMessage
                  component="div"
                  className={classes["invalid-error-message"]}
                  name={field.name}
                />
              </div>
            )}
          </Field>

          <Field name="comment">
            {({ field }: FieldProps) => (
              <div
                className={classNames(
                  classes["comment-field"],
                  classes["field"],
                  classes["customer-visible-field"]
                )}
              >
                <label>Comment</label>
                <InputTextarea {...field} rows={5} maxLength={512} autoResize />
              </div>
            )}
          </Field>
        </Form>
      </FormikProvider>
    </Dialog>
  );
};

export default AdHocTaskFormDialog;
