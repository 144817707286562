const isFeatureFlagEnabled = (flagName: string) => {
  let isFlagEnabled =
    process.env[`REACT_APP_FEATUREFLAG_${flagName}`] == "true";
  const urlSearch = new URLSearchParams(window.location.search);
  const flagParam = urlSearch.get(flagName);

  if (flagParam) {
    isFlagEnabled = flagParam == "true";
  }

  return isFlagEnabled;
};

export const USE_PROJECT_HANDLER_MAPPING = isFeatureFlagEnabled(
  "USE_PROJECT_HANDLER_MAPPING"
);

export const DEFAULT_REFINERS = isFeatureFlagEnabled("DEFAULT_REFINERS");
